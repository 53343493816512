<template>
  <div class="PkDiagram _PkDiagram">
    <div class="cont_tit _cont_tit">
      <div class="title _title">企业租设备 轻资速发展</div>
      <div class="conr_text _conr_text">
        租赁代替购买将降低 <span>60%</span> 办公成本，减少 <span>80%</span>
        资金占用，提高企业现金流使用效率！
      </div>
    </div>
    <div class="cont_pk _cont_pk"></div>
  </div>
</template>

<script>
export default {
  name: "PkDiagram",
};
</script>
<style lang="scss" scoped>
// 大于等于800px
@media only screen and (min-width: 800px) {
  .PkDiagram {
    width: 100%;
    height: 1132px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    margin-top: 60px;
    .cont_tit {
      width: 100%;
      // height: 336px;
      padding-top: 148px;
      padding-bottom: 72px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .title {
        font-family: Microsoft YaHei;
        font-size: 48px;
        font-weight: normal;
        letter-spacing: 0em;
        color: #3d3d3d;
        font-weight: 700;
        margin-bottom: 16px;
      }
      .conr_text {
        font-family: Microsoft YaHei;
        font-size: 28px;
        font-weight: normal;
        letter-spacing: 0em;
        span {
          color: #ec0202;
          font-weight: 700;
        }
      }
    }
    .cont_pk {
      width: 1280px;
      height: 704px;
      background: url("../../../assets/newimg/pk_banner.png") no-repeat center;
      background-size: 100% 100%;
    }
  }
}
// 小于800px
@media only screen and (max-width: 799px) {
  ._PkDiagram {
    width: 100%;
    padding-top: 136px;
    // padding: 28px 12px 20px;
    ._cont_tit {
      padding: 28px 12px 20px;
      ._title {
        text-align: center;
        font-family: Microsoft YaHei;
        font-size: 18px;
        font-weight: normal;
        letter-spacing: 0em;
        color: #3d3d3d;
        font-weight: 700;
        margin-bottom: 8px;
      }
      ._conr_text {
        font-family: Microsoft YaHei;
        font-size: 14px;
        font-weight: normal;
        line-height: 24px;
        letter-spacing: 0em;
        color: #3d3d3d;
        span {
          color: #ec0202;
          font-weight: 700;
        }
      }
    }
    ._cont_pk {
      width: 100%;
      height: 312px;
      background: url("../../../assets/newimg/pk_bannermb.png") no-repeat center;
      background-size: 93.6% 100%;
    }
  }
}
</style>
