<template>
  <div class="Consult _Consult">
    <div class="title _title">1对1专业顾问，免费定制租赁方案</div>
    <div class="main _main">
      <div class="main_top _main_top">
        <img
          src="../../../assets/newimg/zx_phone.png"
          alt=""
          class="zx_img _zx_img"
        />
      </div>
      <div class="cont_box _cont_box">
        <div class="content _content">
          <div class="cont_title _cont_title">预约租赁顾问 主动致电沟通</div>
          <div class="cont_inp _cont_inp">
            <div class="inp_box _inp_box">
              <input
                type="text"
                :class="['text', selected ? 'actived' : '']"
                v-model.trim="companyName"
                placeholder="企业名称（简称）"
                @blur="blurBtno"
                @input="inputBtno()"
              />
              <input
                type="text"
                :class="['textt', selectedt ? 'activedt' : '']"
                v-model.trim="telephone"
                placeholder="联系方式"
                @blur="blurBtnt"
                @input="inputBtnt()"
              />
            </div>
            <textarea
              v-model.trim="requestment"
              :placeholder-style="'#000'"
              placeholder="租赁需求"
              @input="inputBtnf()"
            />
            <div class="zx_btn _zx_btn" @click="toBtn">
              <img
                src="../../../assets/newimg/zx_btn.png"
                alt=""
                class="zxyy_btn _zxyy_btn"
              />
            </div>
          </div>
        </div>
        <div class="main_ri _main_ri">
          <img
            src="../../../assets/newimg/zx_phonet.png"
            alt=""
            class="zx_imgt _zx_imgt"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCorporateLeaseing } from "../../../api";

export default {
  name: "Consult",
  data() {
    return {
      companyName: "",
      telephone: "",
      requestment: "",
      selected: false,
      selectedt: false,
      isTrue: false,
    };
  },
  methods: {
    //手机验证
    ValidatePhone(val) {
      var isPhone = /^1[3456789]\d{9}$/; //手机号码
      var isMob = /^\d{3,4}-\d{7,8}$/; // 座机格式
      // var isMob = /^((0\d{2,3}-\d{7,8})|(1[3584]\d{9}))$/; // 座机格式
      // var isMob = /^\d{3}-\d{7,8}|\d{4}-\d{7,8}$/; // 座机格式
      console.log(isMob.test(val), "ppppp");
      console.log(isPhone.test(val), "ppppp1");
      if (isMob.test(val) || isPhone.test(val)) {
        // if (isPhone.test(val)) {
        return true;
      } else {
        return false;
      }
    },

    inputBtnf() {
      // console.log(this.requestment, "ccccccccc");
    },
    inputBtno() {
      // console.log(this.companyName, "companyName");
    },
    inputBtnt() {
      // console.log(this.telephone, "telephone");
    },
    //失焦校验
    blurBtno() {
      // console.log("blurBtno");
      if (this.companyName) {
        this.selected = false;
      }
    },
    blurBtnt() {
      if (this.telephone) {
        this.selectedt = false;
      }
    },
    getH5Info() {
      const params = {
        userName: this.companyName,
        telephone: this.telephone,
        message: this.requestment,
        type: "企业",
        product: ["电脑"],
      };
      // console.log(params, "dddddddddddddddd");
      getCorporateLeaseing({ ...params }).then(({ data }) => {
        // console.log(data, "hhhhhhhh");
        // console.log(data.status, "hhhhhhhh");
        if (data.status == "2000000") {
          this.companyName = "";
          this.telephone = "";
          this.requestment = "";
          // this.$message.success("预约成功");
          this.$message({
            message: "预约成功",
            type: "success",
            offset: window.screen.height / 10,
          });
        }
      });
    },
    //提交按钮
    toBtn() {
      if (!this.companyName) {
        // this.$message.warning("请填写企业名称");
        this.$message({
          message: "请填写企业名称",
          type: "warning",
          offset: window.screen.height / 10,
        });
        return (this.selected = true);
      }
      if (!this.telephone) {
        // this.$message.warning("请填写联系方式");
        this.$message({
          message: "请填写联系方式",
          type: "warning",
          offset: window.screen.height / 10,
        });
        return (this.selectedt = true);
      }
      // console.log(this.ValidatePhone(this.telephone), "kkkkkkkk");
      if (!this.ValidatePhone(this.telephone)) {
        // this.$message.warning("请填写正确的联系方式");
        this.$message({
          message: "请填写正确的联系方式",
          type: "warning",
          offset: window.screen.height / 10,
        });
        return (this.selectedt = true);
      }
      this.getH5Info();
    },
  },
};
</script>
<style lang="scss" scoped>
img {
  pointer-events: none;
}
// 大于等于800px
@media only screen and (min-width: 800px) {
  .Consult {
    width: 100%;
    height: 788px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f8f9fb;
    .title {
      font-family: Microsoft YaHei;
      font-size: 48px;
      font-weight: normal;
      letter-spacing: 0em;
      color: #3d3d3d;
      font-weight: 700;
      padding-top: 110px;
      padding-bottom: 57px;
    }
    .main {
      width: 1280px;
      height: 486px;
      border-radius: 16px;
      background-color: #fff;
      box-shadow: 0px 8px 16px 0px rgba(17, 114, 253, 0.08);
      .main_top {
        display: none;
      }
      .cont_box {
        width: 100%;
        height: 100%;
        display: flex;
        .content {
          flex: 1;
          display: flex;
          flex-direction: column;
          // align-items: center;
          .cont_title {
            padding: 39px 0 56px;
            font-family: Microsoft YaHei;
            font-size: 32px;
            font-weight: normal;
            text-align: center;
            letter-spacing: 0em;
            color: #3d3d3d;
            font-weight: 700;
          }
          .cont_inp {
            display: flex;
            flex-direction: column;
            align-items: center;
            .inp_box {
              width: 94.71%;
              display: flex;
              justify-content: space-between;
              ::v-deep input {
                width: 63.72%;
                height: 60px;
                border: 1px solid #dddddd;
                border-radius: 4px;
                margin-bottom: 16px;
                text-indent: 16px;
                // outline: none;
                &:focus {
                  outline: 1px solid #33b54f;
                }
                &.actived {
                  border: 1px solid #ec0202;
                }
              }
              ::v-deep input:nth-child(2) {
                width: 34.3%;
                &.activedt {
                  border: 1px solid #ec0202;
                }
              }
            }

            textarea {
              width: 94.71%;
              height: 104px;
              border: 1px solid #dddddd;
              border-radius: 4px;
              text-indent: 16px;
              padding-top: 16px;
              // outline: none;
              resize: none;
              margin-bottom: 40px;
              &:focus {
                outline: 1px solid #33b54f;
              }
            }
            .zx_btn {
              width: 272px;
              height: 72px;
              border-radius: 4px;
              cursor: pointer;
              // animation: myMove 1s infinite; //循环
              // -webkit-animation: myMove 1s infinite;
              // -moz-animation: myMove 1s infinite;
              // -o-animation: myMove 1s infinite;
              // &:hover {
              //   animation: none;
              //   // -webkit-animation: myMove 1s infinite;
              //   // -moz-animation: myMove 1s infinite;
              //   // -o-animation: myMove 1s infinite;
              // }
              .zxyy_btn {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
          }
        }
        .main_ri {
          width: 372px;
          height: 100%;
          border-left: 1px dashed #d8d8d8;
          display: flex;
          justify-content: center;
          align-items: center;
          .zx_imgt {
            width: 285px;
            height: 330px;
            object-fit: cover;
          }
        }
      }
    }
  }
}
// 小于800px
@media only screen and (max-width: 799px) {
  ._Consult {
    width: 100%;
    height: 529px;
    background-color: #f8f9fb;
    display: flex;
    flex-direction: column;
    align-items: center;
    ._title {
      padding: 28px 0 20px;
      font-family: Microsoft YaHei;
      font-size: 18px;
      font-weight: normal;
      text-align: center;
      letter-spacing: 0em;
      color: #3d3d3d;
      font-weight: 700;
    }
    ._main {
      width: 93.6%;
      height: 437px;
      border-radius: 8px;
      background-color: #fff;
      box-shadow: 0px 8px 16px 0px rgba(17, 114, 253, 0.08);
      ._main_top {
        width: 100%;
        height: 78px;
        border-bottom: 1px dashed #d8d8d8;
        display: flex;
        align-items: center;
        justify-content: center;
        ._zx_img {
          width: 209px;
          height: 50px;
          object-fit: cover;
        }
      }
      ._content {
        width: 100%;
        ._cont_title {
          padding: 24px 0;
          font-family: Microsoft YaHei;
          font-size: 14px;
          font-weight: normal;
          text-align: center;
          letter-spacing: 0em;
          color: #3d3d3d;
          font-weight: 700;
        }
        ._cont_inp {
          display: flex;
          flex-direction: column;
          align-items: center;
          ._inp_box {
            width: 93.16%;
            ::v-deep input {
              width: 100%;
              height: 40px;
              border: 1px solid #dddddd;
              border-radius: 4px;
              margin-bottom: 8px;
              text-indent: 12px;
              font-size: 16px;
              outline: none;
              &.actived {
                border: 1px solid #ec0202;
              }
            }
            ::v-deep input:nth-child(2) {
              &.activedt {
                border: 1px solid #ec0202;
              }
            }
          }

          textarea {
            width: 93.16%;
            height: 109px;
            border: 1px solid #dddddd;
            border-radius: 4px;
            text-indent: 12px;
            padding-top: 11px;
            font-size: 16px;
            outline: none;
            resize: none;
            margin-bottom: 20px;
          }
          ._zx_btn {
            width: 135px;
            height: 36px;
            border-radius: 4px;
            ._zxyy_btn {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }
      ._main_ri {
        display: none;
      }
    }
  }
}
@keyframes myMove {
  0% {
    transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
  }

  50% {
    transform: scale(1.2, 1.2);
    -moz-transform: scale(1.2, 1.2);
    -webkit-transform: scale(1.2, 1.2);
    -o-transform: scale(1.2, 1.2);
    -ms-transform: scale(1.2, 1.2);
  }

  100% {
    transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
  }
}
</style>
