<template>
  <div class="CompanyProfile _CompanyProfile">
    <div class="title _title">租赁范围覆盖600+城市</div>
    <div class="lis_box _lis_box">
      <div class="lis _lis">
        <div class="li _li">
          <img
            src="../../../assets/newimg/hzq.png"
            alt=""
            class="sm_img _sm_img _sm_img1"
          />
        </div>
        <div class="li _li">
          <img
            src="../../../assets/newimg/hzone.png"
            alt=""
            class="sm_img _sm_img"
          />
        </div>
        <div class="li _li">
          <img
            src="../../../assets/newimg/hztwo.png"
            alt=""
            class="sm_img _sm_img"
          />
        </div>
        <div class="li _li">
          <img
            src="../../../assets/newimg/hzthree.png"
            alt=""
            class="sm_img _sm_img"
          />
        </div>
        <div class="li _li">
          <img
            src="../../../assets/newimg/hzfour.png"
            alt=""
            class="sm_img _sm_img"
          />
        </div>
        <div class="li _li">
          <img
            src="../../../assets/newimg/hzf.png"
            alt=""
            class="sm_img _sm_img"
          />
        </div>
        <div class="li _li">
          <img
            src="../../../assets/newimg/hzl.png"
            alt=""
            class="sm_img _sm_img"
          />
        </div>
        <div class="li _li">
          <img
            src="../../../assets/newimg/hzb.png"
            alt=""
            class="sm_img _sm_img _sm_img2"
          />
        </div>
      </div>
    </div>
    <div class="main _main">
      <div class="main_top _main_top">
        <div class="top_logo _top_logo">
          <img
            src="../../../assets/newimg/cont_logo.png"
            alt=""
            class="logo_img _logo_img"
          />
        </div>
        <div class="top_cont _top_cont">
          <div class="wz _wz">
            <p>
              广州晟牛科技有限公司(品牌名“租机虫”)是面向全国的专业办公设备免押金租赁平台，为企业提供方便快捷租赁、7x12小时优质维护、高性价比产品的办公设备免押金租赁服务。
            </p>
            <p>
              旨在帮助企业提高资产灵活性、释放更多资金流，享受办公设备管家式服务，降低财务成本，实现真正的轻资产轻办公，帮助企业险低经营试错投入风险。
            </p>
            <p>
              公司拥有自主知识产权的一站式3C设备信用免押租赁服务平台，平台以客户为中心，满足用户“<span>只为使用付费，不为残值买单</span>”的消费需求。
            </p>
          </div>
        </div>
      </div>
      <div class="main_cont _main_cont">
        <div class="block">
          <el-carousel ref="carousel" trigger="click" height="528px">
            <el-carousel-item v-for="item in companyBanner" :key="item.id">
              <img :src="item.pic" alt="" />
            </el-carousel-item>
          </el-carousel>
          <div class="yy">
            <div class="compangName">广州晟牛科技有限公司</div>
          </div>
        </div>
        <div class="_block">
          <el-carousel trigger="click" height="214px">
            <el-carousel-item v-for="item in companyBanner" :key="item.id">
              <img :src="item.pic" alt="" />
            </el-carousel-item>
          </el-carousel>
          <div class="_yy">
            <div class="compangName">广州晟牛科技有限公司</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CompanyProfile",
  data() {
    return {
      companyBanner: [
        {
          id: 1,
          pic: require("../../../assets/newimg/companyo.jpg"),
        },
        {
          id: 2,
          pic: require("../../../assets/newimg/companyt.jpg"),
        },
        {
          id: 3,
          pic: require("../../../assets/newimg/companyf.jpg"),
        },
      ],
    };
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
img {
  pointer-events: none;
}
// 大于等于800px
@media only screen and (min-width: 800px) {
  .CompanyProfile {
    width: 100%;
    height: 969px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f8f9fb;
    .title {
      font-family: Microsoft YaHei;
      font-size: 48px;
      font-weight: normal;
      letter-spacing: 0em;
      color: #3d3d3d;
      font-weight: 700;
      padding-top: 88px;
      padding-bottom: 54px;
    }
    .lis_box {
      width: 100%;
      height: 108px;
      display: flex;
      align-items: center;
      margin-bottom: 40px;
      // display: -webkit-box;
      // overflow-x: auto;
      // &::-webkit-scrollbar {
      //   display: none;
      // }
      .lis {
        // width: 1280px;
        display: flex;
        height: 108px;
        padding-top: 18px;
        display: -webkit-box;
        overflow-x: auto;
        &::-webkit-scrollbar {
          display: none;
        }

        .li {
          width: 240px;
          height: 72px;
          margin-right: 16px;
          &:first-child {
            margin-left: -50px;
          }
          &:nth-child(1),
          &:nth-child(8) {
            opacity: 0.1;
          }
          &:nth-child(2),
          &:nth-child(7) {
            opacity: 0.3;
          }
          &:nth-child(3),
          &:nth-child(6) {
            opacity: 0.6;
          }
          &:nth-child(4),
          &:nth-child(5) {
            opacity: 0.8;
          }
          .sm_img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
    .main {
      width: 1280px;
      height: 528px;
      border-radius: 16px;
      background-color: #fff;
      box-shadow: 0px 8px 16px 0px rgba(17, 114, 253, 0.08);
      display: flex;
      .main_top {
        width: 640px;
        height: 100%;

        .top_logo {
          padding: 32px 0 24px;
          display: flex;
          justify-content: center;
          .logo_img {
            width: 243px;
            height: 70px;
            object-fit: cover;
          }
        }
        .top_cont {
          padding: 0 32px;
          .wz {
            font-family: Microsoft YaHei;
            font-size: 20px;
            font-weight: normal;
            line-height: 32px;
            text-align: justify; /* 浏览器可能不支持 */
            letter-spacing: 0em;
            p {
              text-indent: 2em;
              margin-bottom: 30px;
              span {
                color: #1071fe;
                font-weight: 700;
              }
            }
          }
        }
      }
      .main_cont {
        flex: 1;
        .block {
          position: relative;
          .yy {
            width: 100%;
            height: 120px;
            border-radius: 0px 0px 8px 8px;
            // background: rgba(255, 255, 255, 0.3);
            display: flex;
            // background-color: #757572;
            pointer-events: none;
            position: absolute;
            left: 0;
            bottom: 0;
            .compangName {
              font-family: Microsoft YaHei;
              font-size: 32px;
              width: 100%;
              font-weight: 700;
              letter-spacing: 0em;
              color: #ffffff;
              padding: 39px 40px;
              background: rgba(255, 255, 255, 0.3);
              // background-color: #6e7079ab;
              text-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.3);
              z-index: 9;
            }
          }
          ::v-deep .el-carousel__button {
            width: 42px;
            height: 8px;
            border-radius: 8px 0 8px 0;
          }
        }
        ._block {
          display: none;
        }
        .el-carousel {
          border-radius: 0 8px 8px 0;
        }

        .el-carousel__item img {
          width: 100%;
          height: 528px;
          // object-fit: cover;
        }
      }
    }
  }
}
// 小于800px
@media only screen and (max-width: 799px) {
  ._CompanyProfile {
    width: 100%;
    height: 670px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;

    ._title {
      padding: 28px 0 20px;
      font-family: Microsoft YaHei;
      font-size: 18px;
      font-weight: normal;
      text-align: center;
      letter-spacing: 0em;
      color: #3d3d3d;
      font-weight: 700;
    }
    ._lis_box {
      width: 100%;
      height: 31px;
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      overflow: hidden;
      ._lis {
        width: 100%;
        height: 21px;
        display: flex;
        // display: -webkit-box;
        // overflow-x: auto;
        // &::-webkit-scrollbar {
        //   display: none;
        // }
        ._li {
          width: 69px;
          height: 21px;
          margin-right: 4px;
          &:first-child {
            // margin-left: -40px;
            display: none;
          }
          &:last-child {
            display: none;
          }
          &:nth-child(2) {
            margin-left: -20px;
          }
          ._sm_img {
            width: 68px;
            height: 21px;
            // width: 100%;
            // height: 100%;
            // object-fit: cover;
          }
          ._sm_img1,
          ._sm_img2 {
            display: none;
          }
        }
      }
    }
    ._main {
      width: 93.6%;
      height: 523px;
      border-radius: 8px;
      background-color: #fff;
      box-shadow: 0px 0px 16px 0px rgba(17, 114, 253, 0.08);
      ._main_top {
        width: 100%;
        height: 309px;

        // background-color: #83cae6;
        ._top_logo {
          width: 100%;
          padding: 12px 0 16px;
          display: flex;
          justify-content: center;
          ._logo_img {
            width: 210px;
            height: 61px;
            object-fit: cover;
          }
        }
        ._top_cont {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          ._wz {
            width: 93.16%;
            font-family: Microsoft YaHei;
            font-size: 12px;
            font-weight: normal;
            line-height: 20px;
            text-align: justify; /* 浏览器可能不支持 */
            letter-spacing: 0.05em;
            p {
              text-indent: 2em;
              span {
                color: #1071fe;
                font-weight: 700;
              }
            }
          }
        }
      }
      ._main_cont {
        width: 100%;
        ._block {
          position: relative;
          ._yy {
            width: 100%;
            height: 48px;
            border-radius: 0px 0px 8px 8px;
            // background: rgba(255, 255, 255, 0.3);
            display: flex;
            background-color: #6d6f79;
            pointer-events: none;
            position: absolute;
            left: 0;
            bottom: 0;
            .compangName {
              width: 100%;
              font-family: Microsoft YaHei;
              font-size: 16px;
              font-weight: 700;
              letter-spacing: 0em;
              color: #ffffff;
              padding: 13px 12px;
              background: rgba(255, 255, 255, 0.3);
              text-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.3);
              z-index: 9999;
            }
          }
          ::v-deep .el-carousel__button {
            width: 21px;
            height: 4px;
            border-radius: 4px 0 4px 0;
          }
        }
        .block {
          display: none;
        }
        .el-carousel {
          border-radius: 0 0 8px 8px;
        }
        .el-carousel__item img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}
::v-deep .el-carousel__indicators--horizontal {
  left: 72%;
  // right: 0;
  transform: none;
}
</style>
