<template>
  <div class="GoodsList _GoodsList">
    <div class="title _title">品类丰富 提供专业定制方案</div>
    <div class="main _main">
      <div class="lis _lis">
        <div class="lis_top _lis_top">
          <img
            src="../../../assets/newimg/swbg_bj.png"
            alt=""
            class="bj_img _bj_img"
          />
          <img
            src="../../../assets/newimg/swbj_bjo.png"
            alt=""
            class="bj_imgo _bj_imgo"
          />
        </div>
        <div class="lis_cont _lis_cont">
          <div class="libox _libox">
            <div class="liss _liss">
              <div class="cont_li _cont_li">
                <img
                  src="../../../assets/newimg/swbg_one.png"
                  alt=""
                  class="swbg_pic _swbg_pic"
                />
                <img
                  src="../../../assets/newimg/swbg_oneo.png"
                  alt=""
                  class="swbg_pico _swbg_pico"
                />
                <div class="swbg_title _swbg_title">
                  i5/8G内存/128G固态硬盘+24寸显示器
                </div>
              </div>
              <div class="cont_li _cont_li">
                <img
                  src="../../../assets/newimg/swbg_two.png"
                  alt=""
                  class="swbg_pic _swbg_pic"
                />
                <img
                  src="../../../assets/newimg/swbg_twoo.png"
                  alt=""
                  class="swbg_pico _swbg_pico"
                />
                <div class="swbg_title _swbg_title">
                  i5/8G内存/128G固态硬盘/24寸
                </div>
              </div>
            </div>
            <div class="lis_name _lis_name">
              <div class="li_name _li_name">
                新客特惠
                <span class="li_col _li_col"
                  ><span class="lg _lg">69</span>元/月</span
                >
                <span class="li_no _li_no">￥76</span>
              </div>
              <div class="li_name _li_name">
                新客特惠
                <span class="li_col _li_col"
                  ><span class="lg _lg">79</span>元/月</span
                >
                <span class="li_no _li_no">￥83</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="lis _lis">
        <div class="topli _topli">
          <div class="lis_top _lis_top">
            <img
              src="../../../assets/newimg/bjbg_bj.png"
              alt=""
              class="bj_imgo _bj_img"
            />
            <img
              src="../../../assets/newimg/bjbg_bjo.png"
              alt=""
              class="bj_imgo _bj_imgo"
            />
          </div>
        </div>
        <div class="lis_cont _lis_cont">
          <div class="libox _libox">
            <div class="liss _liss">
              <div class="cont_li _cont_li">
                <img
                  src="../../../assets/newimg/bjbg_one.png"
                  alt=""
                  class="swbg_pic _swbg_pic"
                />
                <img
                  src="../../../assets/newimg/bjbg_oneo.png"
                  alt=""
                  class="swbg_pico _swbg_pico"
                />
                <div class="swbg_title _swbg_title">
                  i5/8G内存/128G固态硬盘/14英寸
                </div>
              </div>
              <div class="cont_li _cont_li">
                <img
                  src="../../../assets/newimg/bjbg_two.png"
                  alt=""
                  class="swbg_pic _swbg_pic"
                />
                <img
                  src="../../../assets/newimg/bjbg_twoo.png"
                  alt=""
                  class="swbg_pico _swbg_pico"
                />
                <div class="swbg_title _swbg_title">
                  i5/8G内存/240G固态硬盘/14英寸
                </div>
              </div>
            </div>
            <div class="lis_name _lis_name">
              <div class="li_name _li_name">
                新客特惠
                <span class="li_col _li_col"
                  ><span class="lg _lg">79</span>元/月</span
                >
                <span class="li_no _li_no">￥85</span>
              </div>
              <div class="li_name _li_name">
                新客特惠
                <span class="li_col _li_col"
                  ><span class="lg _lg">95</span>元/月</span
                >
                <span class="li_no _li_no">￥106</span>
              </div>
            </div>
          </div>
        </div>
        <div class="rili _rili">
          <div class="lis_top _lis_top">
            <img
              src="../../../assets/newimg/bjbg_bj.png"
              alt=""
              class="bj_img _bj_img"
            />
            <img
              src="../../../assets/newimg/bjbg_bjo.png"
              alt=""
              class="bj_imgo _bj_imgo"
            />
          </div>
        </div>
      </div>
      <div class="lis _lis">
        <div class="lis_top _lis_top">
          <img
            src="../../../assets/newimg/sjjj_bj.png"
            alt=""
            class="bj_img _bj_img"
          />
          <img
            src="../../../assets/newimg/sjjj_bjo.png"
            alt=""
            class="bj_imgo _bj_imgo"
          />
        </div>
        <div class="lis_cont _lis_cont">
          <div class="libox _libox">
            <div class="liss _liss">
              <div class="cont_li _cont_li">
                <img
                  src="../../../assets/newimg/sjjj_one.png"
                  alt=""
                  class="swbg_pic _swbg_pic"
                />
                <img
                  src="../../../assets/newimg/sjjj_oneo.png"
                  alt=""
                  class="swbg_pico _swbg_pico"
                />
                <div class="swbg_title _swbg_title">
                  i510代/16G/240G SSD 1Tb hdd/1050Ti
                </div>
              </div>
              <div class="cont_li _cont_li">
                <img
                  src="../../../assets/newimg/sjjj_two.png"
                  alt=""
                  class="swbg_pic _swbg_pic"
                />
                <img
                  src="../../../assets/newimg/sjjj_twoo.png"
                  alt=""
                  class="swbg_pico _swbg_pico"
                />
                <div class="swbg_title _swbg_title">
                  i5-9400F/16G/240G/RX550/24寸
                </div>
              </div>
            </div>
            <div class="lis_name _lis_name">
              <div class="li_name _li_name">
                新客特惠
                <span class="li_col _li_col"
                  ><span class="lg _lg">209</span>元/月</span
                >
                <span class="li_no _li_no">￥249</span>
              </div>
              <div class="li_name _li_name">
                新客特惠
                <span class="li_col _li_col"
                  ><span class="lg _lg">168</span>元/月</span
                >
                <span class="li_no _li_no">￥195</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="cont _cont">
        <div class="name _name">
          现在咨询定制 锁定<span class="sd _sd">首单六折起</span>优惠
        </div>
        <div class="ljzx _ljzx" @click="ddHrefLogin">
          <!-- <img
            src="../../../assets/newimg/ljzx_icon.png"
            alt=""
            class="btn_icon _btn_icon"
          /> -->
        </div>
        <div class="con_text _con_text">* 个人租赁不支持享有该优惠</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GoodsList",
  methods: {
    // 跳转链接钉钉操作
    ddHrefLogin() {
      console.log("888888888");
      // const { agentId, url } = this.ddConfig;
      // const cloneUrl = encodeURIComponent(url);
      // const cloneUrl = "https://cschat.antcloud.com.cn/index.htm";
      const agentId = "ilh_9ol2&scene=SCE01236050";
      const hrefUrl = `https://cschat.antcloud.com.cn/index.htm?tntInstId=${agentId}`;
      window.open(
        hrefUrl,
        "_blank",
        "page2.html",
        "newwindow2",
        "width=100,height=100, top=100, left=100,toolbar=no, menubar=no, scrollbars=no, resizable=no, location=no, status=no,"
      );
    },
  },
};
</script>
<style lang="scss" scoped>
img {
  pointer-events: none;
}
// 大于等于800px
@media only screen and (min-width: 800px) {
  .GoodsList {
    width: 100%;
    height: 2268px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    .title {
      font-family: Microsoft YaHei;
      font-size: 48px;
      font-weight: normal;
      letter-spacing: 0em;
      color: #3d3d3d;
      font-weight: 700;
      padding-top: 110px;
      padding-bottom: 48px;
    }
    .main {
      width: 1280px;
      height: 100%;
      // background-color: #89e3f3;
      .lis {
        width: 1280px;
        height: 561px;
        border-radius: 16px;
        background: #ffffff;
        box-sizing: border-box;
        border: 1px solid #1377fe;
        margin-bottom: 32px;
        display: flex;
        &:nth-child(2) {
          border: 1px solid #fe894a;
          .lis_top {
            .bj_imgo {
              width: 100%;
              height: 100%;
              border-radius: 0px 16px 16px 0px;
            }
          }
        }
        &:nth-child(3) {
          border: 1px solid #9f26e5;
          margin-bottom: 0px !important;
        }
        .topli {
          display: none;
        }
        .lis_top {
          width: 268px;
          height: 100%;
          border-radius: 16px 0px 0px 16px;
          .bj_img {
            display: none;
          }
          .bj_imgo {
            width: 100%;
            height: 100%;
            border-radius: 16px 0px 0px 16px;
          }
        }
        .lis_cont {
          flex: 1;
          height: 100%;
          .libox {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            .liss {
              width: 97.87%;
              // height: 100%;
              height: 492px;
              background-color: #fff;
              display: flex;
              justify-content: space-between;
              align-items: center;
              .cont_li {
                width: 490px;
                height: 476px;
                border-radius: 8px;
                background: #d9e9ff;
                display: flex;
                flex-direction: column;
                align-items: center;
                .swbg_pic {
                  display: none;
                }
                .swbg_pico {
                  width: 458px;
                  height: 380px;
                  border-radius: 8px;
                  margin: 16px 0 24px;
                }
                .swbg_title {
                  font-family: Microsoft YaHei;
                  font-size: 24px;
                  font-weight: normal;
                  text-align: center;
                  letter-spacing: 0em;
                  color: #3d3d3d;
                  font-weight: 700;
                }
              }
            }
            .lis_name {
              width: 100%;
              display: flex;
              align-items: center;
              .li_name {
                width: 50%;
                font-family: Microsoft YaHei;
                font-size: 24px;
                letter-spacing: 0em;
                font-weight: 700;
                padding-left: 24px;
                &:last-child {
                  padding-right: 24px;
                }
                .li_col {
                  color: #ec0202;

                  margin-right: 12px;
                  padding-left: 14px;
                  .lg {
                    font-size: 40px;
                  }
                }
                .li_no {
                  color: #aaaaaa;
                  text-decoration: line-through;
                }
              }
            }
          }
        }
      }
    }
    .cont {
      padding: 72px 0 50px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .name {
        font-family: Microsoft YaHei;
        font-size: 28px;
        font-weight: normal;
        text-align: center;
        letter-spacing: 0em;
        font-weight: 700;
        // color: #3d3d3d;
        .sd {
          color: #ec0202;
        }
      }
      .ljzx {
        width: 270px;
        height: 72px;
        margin: 24px 0;
        background: url("../../../assets/newimg/ljzx_icon.png") no-repeat center;
        background-size: 100% 100%;
        cursor: pointer;
        animation: myMove 1s infinite; //循环
        -webkit-animation: myMove 1s infinite;
        -moz-animation: myMove 1s infinite;
        -o-animation: myMove 1s infinite;
        &:hover {
          animation: none;
          background: url("../../../assets/newimg/ljzx_open.png") no-repeat
            center;
          background-size: 100% 100%;
          // -webkit-animation: myMove 1s infinite;
          // -moz-animation: myMove 1s infinite;
          // -o-animation: myMove 1s infinite;
        }
        .btn_icon {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .con_text {
        font-family: Microsoft YaHei;
        font-size: 16px;
        font-weight: normal;
        text-align: center;
        letter-spacing: 0em;
        color: #aaaaaa;
      }
    }
  }
}
// 小于800px
@media only screen and (max-width: 799px) {
  ._GoodsList {
    width: 100%;
    min-height: 1169px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    ._title {
      padding: 28px 0 20px;
      font-family: Microsoft YaHei;
      font-size: 18px;
      font-weight: normal;
      text-align: center;
      letter-spacing: 0em;
      color: #3d3d3d;
      font-weight: 700;
    }
    ._main {
      width: 95.73%;
      height: 100%;
      ._lis {
        width: 100%;
        min-height: 309px;
        border-radius: 8px;
        background: #ffffff;
        box-sizing: border-box;
        border: 1px solid #1377fe;
        margin-bottom: 17px;
        &:nth-child(2) {
          border: 1px solid #fe894a;
          ._lis_cont {
            ._libox {
              ._liss {
                ._cont_li {
                  background: #ffe0d0;
                }
              }
            }
          }
        }
        &:nth-child(3) {
          border: 1px solid #9f26e5;
          margin-bottom: 0px;
          ._lis_cont {
            ._libox {
              ._liss {
                ._cont_li {
                  background: #f4e3ff;
                }
              }
            }
          }
        }
        ._rili {
          display: none;
        }
        ._lis_top {
          width: 100%;
          height: 48px;
          border-radius: 8px 8px 0px 0px;
          ._bj_img {
            width: 100%;
            height: 100%;
            border-radius: 8px 8px 0px 0px;
            object-fit: cover;
          }
          ._bj_imgo {
            display: none;
          }
        }
        ._lis_cont {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          ._libox {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            ._liss {
              width: 97.87%;
              // height: 226px;
              display: flex;
              // align-items: center;
              justify-content: space-between;
              padding-top: 8px;
              ._cont_li {
                width: 49%;
                // height: 218px;
                border-radius: 4px;
                background: #d9e9ff;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding-bottom: 8px;
                &:last-child {
                  margin-right: 0;
                }
                ._swbg_pic {
                  width: 166px;
                  height: 166px;
                  border-radius: 4px;
                  margin: 4px 0 8px;
                }
                ._swbg_pico {
                  display: none;
                }
                ._swbg_title {
                  font-family: Microsoft YaHei;
                  font-size: 12px;
                  font-weight: normal;
                  letter-spacing: 0em;
                  font-weight: 700;
                  color: #3d3d3d;
                  margin: 0 8px;
                  word-break: break-all;
                }
              }
            }
            ._lis_name {
              width: 100%;
              display: flex;
              align-items: center;
              ._li_name {
                width: 50%;
                font-family: Microsoft YaHei;
                font-size: 12px;
                font-weight: normal;
                letter-spacing: 0em;
                font-weight: 700;
                padding-left: 8px;
                padding: 4px 8px 8px 8px;
                // &:last-child {
                //   padding-right: 8px;
                // }
                ._li_col {
                  color: #ec0202;
                  margin-right: 6px;
                  padding-left: 4px;
                  .lg {
                    font-size: 20px;
                  }
                }
                ._li_no {
                  color: #aaaaaa;
                  text-decoration: line-through;
                }
              }
            }
          }
        }
      }
    }
    ._cont {
      padding: 28px 0 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      ._name {
        font-family: Microsoft YaHei;
        font-size: 14px;
        font-weight: normal;
        text-align: center;
        letter-spacing: 0em;
        font-weight: 700;
        // color: #3d3d3d;
        ._sd {
          color: #ec0202;
        }
      }
      ._ljzx {
        width: 135px;
        height: 36px;
        margin: 12px 0;
        background: url("../../../assets/newimg/ljzx_icon.png") no-repeat center;
        background-size: 100% 100%;
        ._btn_icon {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      ._con_text {
        font-family: Microsoft YaHei;
        font-size: 10px;
        font-weight: normal;
        text-align: center;
        letter-spacing: 0em;
        color: #aaaaaa;
      }
    }
  }
}
@keyframes myMove {
  0% {
    transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
  }

  50% {
    transform: scale(1.2, 1.2);
    -moz-transform: scale(1.2, 1.2);
    -webkit-transform: scale(1.2, 1.2);
    -o-transform: scale(1.2, 1.2);
    -ms-transform: scale(1.2, 1.2);
  }

  100% {
    transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
  }
}
</style>
