<template>
  <div class="home">
    <Rotation />
    <RotationLis />
    <PkDiagram />
    <SaveTime />
    <GoodsList />
    <Consult />
    <CompanyProfile />
    <TophoneDialog />
  </div>
</template>

<script>
import CompanyProfile from "./components/CompanyProfile.vue";
import Consult from "./components/Consult.vue";
import GoodsList from "./components/GoodsList.vue";
import PkDiagram from "./components/PkDiagram.vue";
import Rotation from "./components/Rotation.vue";
import RotationLis from "./components/RotationLis.vue";
import SaveTime from "./components/SaveTime.vue";
import TophoneDialog from "./components/TophoneDialog.vue";
export default {
  name: "home",
  components: {
    Rotation,
    RotationLis,
    PkDiagram,
    SaveTime,
    GoodsList,
    Consult,
    CompanyProfile,
    TophoneDialog,
  },
  props: {},
  data() {
    return {
      oldScrollTop: 0, //记录上一次滚动结束后的滚动距离
      scrollTop: 0, // 记录当前的滚动距离
    };
  },
  created() {},
  mounted() {
    if (this._isMobile()) {
      // console.log("手机端");
      window.addEventListener("scroll", this.handleScroll);
    } else {
      console.log("pc端");
    }
  },
  watch: {
    scrollTop(newValue, oldValue) {
      setTimeout(() => {
        if (newValue == this.scrollTop) {
          //延时执行后当newValue等于window.scrollY，代表滚动结束
          // console.log("滚动结束");
          // document.getElementsByClassName("TophoneDialog")[0].style.display =
          //   "none";
          this.oldScrollTop = newValue; //每次滚动结束后都要给oldScrollTop赋值
          document.getElementsByClassName("TophoneDialog")[0].style.display =
            "block";
        }
      }, 2000); //必须使用延时器，否则每次newValue和window.scrollY都相等，无法判断，20ms刚好大于watch的侦听周期，故延时20ms
      if (this.oldScrollTop == oldValue) {
        //每次滚动开始时oldScrollTop与oldValue相等
        // console.log("滚动开始");
        document.getElementsByClassName("TophoneDialog")[0].style.display =
          "none";
        // setInterval(() => {
        //   document.getElementsByClassName("TophoneDialog")[0].style.display =
        //     "block";
        // }, 2000);
      }
    },
  },
  computed: {},
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },

    // 获取页面滚动距离
    handleScroll() {
      let scrollTops =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // console.log(scrollTops, "滚动距离");
      this.scrollTop = scrollTops;
    },
    // handleContact(e) {
    //   console.log("22222222222222");
    //   console.log(e.detail.path);
    //   console.log(e.detail.query);
    // },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
<style lang="scss" scoped></style>
