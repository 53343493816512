import request from "@/utils/request";
//企业租赁
export function getCorporateLeaseing(data) {
  return request({
    method: "POST",
    url: "/index/applyCorporateLeaseing.json",
    // url: "/user/applyCorporateLeaseing.json",
    data,
  });
}
