<template>
  <div class="SaveTime _SaveTime">
    <div class="title _title">省时省心省钱 解决企业设备难题</div>
    <div class="lis _lis">
      <div class="li _li">
        <div
          class="sm_box _sm_box"
          @mouseenter="mouseenter(1)"
          @mouseleave="mouseleave(1)"
        >
          <img
            src="../../../assets/newimg/time_one.png"
            alt=""
            class="sm_img sm_img1 _sm_img"
            style="display: block"
          />
          <img
            src="../../../assets/newimg/time_oneh.png"
            alt=""
            class="sm_imgh sm_imgh1 _sm_imgh"
            style="display: none"
          />
        </div>
      </div>
      <div class="li _li">
        <div
          class="sm_box _sm_box"
          @mouseenter="mouseenter(2)"
          @mouseleave="mouseleave(2)"
        >
          <img
            src="../../../assets/newimg/time_two.png"
            alt=""
            class="sm_img sm_img2 _sm_img"
          />
          <img
            src="../../../assets/newimg/time_twoh.png"
            alt=""
            class="sm_imgh sm_imgh2 _sm_imgh"
            style="display: none"
          />
        </div>
      </div>
      <div class="li _li">
        <div
          class="sm_box _sm_box"
          @mouseenter="mouseenter(3)"
          @mouseleave="mouseleave(3)"
        >
          <img
            src="../../../assets/newimg/time_three.png"
            alt=""
            class="sm_img sm_img3 _sm_img"
          />
          <img
            src="../../../assets/newimg/time_threeh.png"
            alt=""
            class="sm_imgh sm_imgh3 _sm_imgh"
            style="display: none"
          />
        </div>
      </div>
      <div class="li _li">
        <div
          class="sm_box _sm_box"
          @mouseenter="mouseenter(4)"
          @mouseleave="mouseleave(4)"
        >
          <img
            src="../../../assets/newimg/time_four.png"
            alt=""
            class="sm_img sm_img4 _sm_img"
          />
          <img
            src="../../../assets/newimg/time_fourh.png"
            alt=""
            class="sm_imgh sm_imgh4 _sm_imgh"
            style="display: none"
          />
        </div>
      </div>
    </div>
    <div class="cont _cont">
      <div class="name _name">
        现在咨询定制 锁定<span class="sd _sd">首单六折起</span>优惠
      </div>
      <div class="ljzx _ljzx" @click="ddHrefLogin">
        <!-- <img
          src="../../../assets/newimg/ljzx_icon.png"
          alt=""
          class="btn_icon _btn_icon"
        /> -->
      </div>
      <div class="con_text _con_text">* 个人租赁不支持享有该优惠</div>
    </div>
    <!-- <div class="cont_pk _cont_pk"></div> -->
  </div>
</template>

<script>
export default {
  name: "SaveTime",
  data() {
    return {
      isMove: 0,
    };
  },
  mounted() {
    if (this._isMobile()) {
      this.isMove = 0;
      console.log("手机端");
    } else {
      this.isMove = 1;
      this.mouseenter();
      this.mouseleave();
      console.log("pc端");
    }
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    //跳到对应位置
    // handScorTap() {
    //   document.getElementsByClassName("_Consult")[0].scrollIntoView();
    // },
    // 跳转链接钉钉操作
    ddHrefLogin() {
      console.log("888888888");
      // const { agentId, url } = this.ddConfig;
      // const cloneUrl = encodeURIComponent(url);
      // const cloneUrl = "https://cschat.antcloud.com.cn/index.htm";
      const agentId = "ilh_9ol2&scene=SCE01236050";
      const hrefUrl = `https://cschat.antcloud.com.cn/index.htm?tntInstId=${agentId}`;
      window.open(
        hrefUrl,
        "_blank",
        "page2.html",
        "newwindow2",
        "width=100,height=100, top=100, left=100,toolbar=no, menubar=no, scrollbars=no, resizable=no, location=no, status=no,"
      );
    },
    //移入
    mouseenter(val) {
      console.log(val, "11111");
      if (this.isMove == 1) {
        if (val == 1) {
          document.getElementsByClassName("sm_imgh1")[0].style.display =
            "block";
          document.getElementsByClassName("sm_img1")[0].style.display = "none";
        } else if (val == 2) {
          document.getElementsByClassName("sm_imgh2")[0].style.display =
            "block";
          document.getElementsByClassName("sm_img2")[0].style.display = "none";
        } else if (val == 3) {
          document.getElementsByClassName("sm_imgh3")[0].style.display =
            "block";
          document.getElementsByClassName("sm_img3")[0].style.display = "none";
        } else if (val == 4) {
          document.getElementsByClassName("sm_imgh4")[0].style.display =
            "block";
          document.getElementsByClassName("sm_img4")[0].style.display = "none";
        }
      }
    },
    //移出
    mouseleave(val) {
      console.log("9999");
      if (this.isMove == 1) {
        if (val == 1) {
          document.getElementsByClassName("sm_imgh1")[0].style.display = "none";
          document.getElementsByClassName("sm_img1")[0].style.display = "block";
        } else if (val == 2) {
          document.getElementsByClassName("sm_imgh2")[0].style.display = "none";
          document.getElementsByClassName("sm_img2")[0].style.display = "block";
        } else if (val == 3) {
          document.getElementsByClassName("sm_imgh3")[0].style.display = "none";
          document.getElementsByClassName("sm_img3")[0].style.display = "block";
        } else if (val == 4) {
          document.getElementsByClassName("sm_imgh4")[0].style.display = "none";
          document.getElementsByClassName("sm_img4")[0].style.display = "block";
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
img {
  pointer-events: none;
}
// 大于等于800px
@media only screen and (min-width: 800px) {
  .SaveTime {
    width: 100%;
    min-height: 943px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f8f9fb;
    .title {
      font-family: Microsoft YaHei;
      font-size: 48px;
      font-weight: normal;
      letter-spacing: 0em;
      color: #3d3d3d;
      font-weight: 700;
      padding-top: 84px;
      padding-bottom: 88px;
    }
    .lis {
      width: 100%;
      min-height: 382px;
      display: flex;
      align-items: center;
      justify-content: center;
      .li {
        width: 314px;
        height: 100%;
        border-radius: 16px;
        margin-right: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:last-child {
          margin-right: 0;
        }
        .sm_box {
          // width: 100%;
          // height: 100%;
          cursor: pointer;
          .sm_img {
            width: 100%;
            height: 382px;
            object-fit: cover;
          }
          .sm_imgh {
            width: 100%;
            height: 464px;
            object-fit: cover;
          }
        }
      }
    }
    .cont {
      padding: 98px 0 50px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .name {
        font-family: Microsoft YaHei;
        font-size: 28px;
        font-weight: normal;
        text-align: center;
        letter-spacing: 0em;
        font-weight: 700;
        // color: #3d3d3d;
        .sd {
          color: #ec0202;
        }
      }
      .ljzx {
        width: 270px;
        height: 72px;
        margin: 24px 0;
        background: url("../../../assets/newimg/ljzx_icon.png") no-repeat center;
        background-size: 100% 100%;
        animation: myMove 1s infinite; //循环
        -webkit-animation: myMove 1s infinite;
        -moz-animation: myMove 1s infinite;
        -o-animation: myMove 1s infinite;
        cursor: pointer;
        &:hover {
          animation: none;
          background: url("../../../assets/newimg/ljzx_open.png") no-repeat
            center;
          background-size: 100% 100%;
          // -webkit-animation: myMove 1s infinite;
          // -moz-animation: myMove 1s infinite;
          // -o-animation: myMove 1s infinite;
        }
        .btn_icon {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .con_text {
        font-family: Microsoft YaHei;
        font-size: 16px;
        font-weight: normal;
        text-align: center;
        letter-spacing: 0em;
        color: #aaaaaa;
      }
    }
  }
}
// 小于800px
@media only screen and (max-width: 799px) {
  ._SaveTime {
    width: 100%;
    height: 403px;
    background-color: #f8f9fb;
    // padding-top: 28px;
    // padding: 28px 12px 20px;
    ._title {
      padding: 28px 0 20px;
      font-family: Microsoft YaHei;
      font-size: 18px;
      font-weight: normal;
      text-align: center;
      letter-spacing: 0em;
      color: #3d3d3d;
      font-weight: 700;
    }
    ._lis {
      width: 100%;
      height: 191px;
      // padding-left: 12px;
      display: -webkit-box;
      overflow-x: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      ._li {
        width: 157px;
        height: 100%;
        border-radius: 16px;
        // background-color: #fff;
        // box-shadow: 0px 16px 32px 0px rgba(17, 114, 253, 0.08);
        // padding: 0.47%;
        // padding: 0 16px;
        margin-right: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:first-child {
          margin-left: 12px;
        }
        ._sm_img {
          // width: 28.03%;
          // height: auto;
          // margin-right: 8px;
          width: 108%;
          height: 100%;
          object-fit: cover;
        }
        ._sm_imgh {
          display: none;
        }
      }
    }
    ._cont {
      padding: 28px 0 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      ._name {
        font-family: Microsoft YaHei;
        font-size: 14px;
        font-weight: normal;
        text-align: center;
        letter-spacing: 0em;
        font-weight: 700;
        // color: #3d3d3d;
        ._sd {
          color: #ec0202;
        }
      }
      ._ljzx {
        width: 135px;
        height: 36px;
        margin: 12px 0;
        background: url("../../../assets/newimg/ljzx_icon.png") no-repeat center;
        background-size: 100% 100%;
        ._btn_icon {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      ._con_text {
        font-family: Microsoft YaHei;
        font-size: 10px;
        font-weight: normal;
        text-align: center;
        letter-spacing: 0em;
        color: #aaaaaa;
      }
    }
  }
}
@keyframes myMove {
  0% {
    transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
  }

  50% {
    transform: scale(1.2, 1.2);
    -moz-transform: scale(1.2, 1.2);
    -webkit-transform: scale(1.2, 1.2);
    -o-transform: scale(1.2, 1.2);
    -ms-transform: scale(1.2, 1.2);
  }

  100% {
    transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
  }
}
</style>
