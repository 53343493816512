import axios from "axios";
// import router from "@/router/index";
import { Message } from "element-ui";

const service = axios.create({
  baseURL: "/zjcApi", // url = base url + request url //测试
  // baseURL: "https://new-api.shengniuuz.com", // url = base url + request url //测试
  // baseURL: "https://snpc-api.shengniuuz.com/api", // url = base url + request url //正式
  timeout: 5000, // request timeout
});

// 请求拦截器
// request interceptor
service.interceptors.request.use((config) => {
  return config;
});

// 响应拦截器
service.interceptors.response.use(
  (res) => {
    const { status, message } = res.data;
    console.log(res.data, "uuuuuuuuuuuuuuuuuuu");
    if (status != "5000000") {
      return res;
    } else {
      Message.error(message);
      return Promise.reject(new Error(message));
    }
  },
  (err) => {
    Message.error(err.message);
    return Promise.reject(err);
  }
);

// 暴漏实例
export default service;
