<template>
  <div class="rotation _rotation">
    <div class="main_cont _main_cont">
      <div class="block">
        <el-carousel trigger="click" height="704px">
          <el-carousel-item
            v-for="item in BannerList"
            :key="item.id"
            @click.native="ddHrefLogin"
          >
            <img :src="item.pic" alt="" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="_block">
        <el-carousel trigger="click" height="206px">
          <el-carousel-item
            v-for="item in BannerListb"
            :key="item.id"
            @click.native="ddHrefLogin"
          >
            <img :src="item.pic" alt="" />
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "rotation",
  data() {
    return {
      BannerList: [
        {
          id: 1,
          pic: require("../../../assets/newimg/banner_bg.png"),
        },
      ],
      BannerListb: [
        {
          id: 1,
          pic: require("../../../assets/newimg/banner.png"),
        },
      ],
    };
  },
  methods: {
    // 跳转链接钉钉操作
    ddHrefLogin() {
      console.log("888888888");
      // const { agentId, url } = this.ddConfig;
      // const cloneUrl = encodeURIComponent(url);
      // const cloneUrl = "https://cschat.antcloud.com.cn/index.htm";
      const agentId = "ilh_9ol2&scene=SCE01236050";
      const hrefUrl = `https://cschat.antcloud.com.cn/index.htm?tntInstId=${agentId}`;
      window.open(
        hrefUrl,
        "_blank",
        "page2.html",
        "newwindow2",
        "width=100,height=100, top=100, left=100,toolbar=no, menubar=no, scrollbars=no, resizable=no, location=no, status=no,"
      );
    },
  },
};
</script>
<style lang="scss" scoped>
img {
  pointer-events: none;
}
// 大于等于800px
@media only screen and (min-width: 800px) {
  .rotation {
    width: 100%;
    // height: 352px;
    height: 704px;
    background: linear-gradient(111deg, #e2eeff 23%, #d1e4ff 94%);
    margin-top: 80px;
    .main_cont {
      width: 100%;
      height: 100%;
      ._block {
        display: none;
      }
      .el-carousel__item img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      ::v-deep .el-carousel__indicators--horizontal {
        display: none;
      }
    }
  }
}
// 小于800px
@media only screen and (max-width: 799px) {
  ._rotation {
    width: 100%;
    height: 206px;
    background: linear-gradient(120deg, #d4e6ff 22%, #a9ceff 93%);
    margin-top: 60px;
    ._main_cont {
      width: 100%;
      height: 100%;
      .block {
        display: none;
      }
      .el-carousel__item img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      ::v-deep .el-carousel__indicators--horizontal {
        display: none;
      }
    }
  }
}
</style>
