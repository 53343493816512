<template>
  <div class="rotationlis _rotationlis">
    <div class="lis _lis">
      <div class="box_lis _box_lis">
        <div class="li _li">
          <img
            src="../../../assets/newimg/banner_lionesm.png"
            alt=""
            class="sm_img _sm_img"
          />
          <img
            src="../../../assets/newimg/qo.png"
            alt=""
            class="bg_img _bg_img"
          />
          <div class="tiview _tiview">
            <div class="titl _titl">专业服务</div>
            <div class="tagl _tagl">1对1服务 同城上门服务</div>
          </div>
        </div>
        <div class="li _li">
          <img
            src="../../../assets/newimg/banner_litwosm.png"
            alt=""
            class="sm_img _sm_img"
          />
          <img
            src="../../../assets/newimg/qt.png"
            alt=""
            class="bg_img _bg_img"
          />
          <div class="tiview _tiview">
            <div class="titl _titl">设备齐全</div>
            <div class="tagl _tagl">笔记本丨台式机丨打印机 ...</div>
          </div>
        </div>
        <div class="li _li">
          <img
            src="../../../assets/newimg/banner_lithreesm.png"
            alt=""
            class="sm_img _sm_img"
          />
          <img
            src="../../../assets/newimg/qf.png"
            alt=""
            class="bg_img _bg_img"
          />
          <div class="tiview _tiview">
            <div class="titl _titl">下单免押金</div>
            <div class="tagl _tagl">企业客户 专享免押</div>
          </div>
        </div>
        <div class="li _li">
          <img
            src="../../../assets/newimg/banner_lifoursm.png"
            alt=""
            class="sm_img _sm_img"
          />
          <img
            src="../../../assets/newimg/qs.png"
            alt=""
            class="bg_img _bg_img"
          />
          <div class="tiview _tiview">
            <div class="titl _titl">灵活定制</div>
            <div class="tagl _tagl">1台起租 1天起租 配置可选</div>
          </div>
        </div>
        <!-- <div class="li _li">
          <img
            src="../../../assets/newimg/banner_lionesm.png"
            alt=""
            class="sm_img _sm_img"
          />
          <img
            src="../../../assets/newimg/qo.png"
            alt=""
            class="bg_img _bg_img"
          />
          <div class="tiview _tiview">
            <div class="titl _titl">专业服务</div>
            <div class="tagl _tagl">1对1服务 同城上门服务</div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "rotationlis",
  data() {
    return {
      leftnum: 0,
    };
  },
  mounted() {
    if (this._isMobile()) {
      this.zdFun();
    }
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    zdFun() {
      // setInterval(this.next, 3000);
    },
    next() {
      const box = document.getElementsByClassName("_box_lis");
      // console.log(box, "oooooooooooooooo1");
      this.leftnum += 80;
      // console.log(box[0].offsetWidth, "hhhhhhhhhhh");
      if (this.leftnum >= box[0].offsetWidth) {
        this.leftnum = 0;
      }
      box[0].style = "transform:translateX(-" + this.leftnum + "px)";
      // console.log(box[0].style.transform, "oooooooooooooooo2");
    },
  },
};
</script>
<style lang="scss" scoped>
img {
  pointer-events: none;
}
// 大于等于800px
@media only screen and (min-width: 800px) {
  .rotationlis {
    width: 100%;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    // margin: -60px 0;
    position: absolute;
    left: 0;
    top: 724px;
    z-index: 9;
    .lis {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .box_lis {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .li {
          width: 282px;
          height: 100%;
          border-radius: 16px;
          background-color: #fff;
          box-shadow: 0px 16px 32px 0px rgba(17, 114, 253, 0.08);
          // padding: 0.47%;
          padding: 0 16px;
          margin-right: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          &:last-child {
            margin-right: 0;
          }
          .sm_img {
            width: 28.03%;
            height: auto;
            margin-right: 8px;
          }
          .tiview {
            display: flex;
            flex: 1;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
          }
          .titl {
            font-family: Microsoft YaHei;
            font-size: 20px;
            font-weight: normal;
            letter-spacing: 0em;
            color: #3d3d3d;
            margin-top: 9px;
            margin-bottom: 4px;
            font-weight: 600;
          }
          .tagl {
            font-family: Microsoft YaHei;
            font-size: 14px;
            font-weight: normal;
            letter-spacing: 0em;

            color: #999999;
          }
          .bg_img {
            display: none;
          }
        }
      }
      ._box_lis {
        // display: none;
      }
    }
  }
}
// 小于800px
@media only screen and (max-width: 799px) {
  ._rotationlis {
    // min-width: 100%;
    height: 158px;
    // padding-left: 12px;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // margin: -8px 0;
    width: 100%;
    padding-bottom: 16px;
    z-index: 9;
    position: absolute;
    left: 0;
    top: 258px;
    ._lis {
      width: 100%;
      height: 100%;

      display: -webkit-box;
      overflow-x: auto;
      overflow-y: hidden;
      &::-webkit-scrollbar {
        display: none;
      }
      ._box_lis {
        width: 100%;
        height: 100%;
        display: -webkit-box;
        ._li {
          width: 140px;
          height: 142px;
          border-radius: 4px;
          background-color: #fff;
          box-shadow: 0px 8px 16px 0px rgba(17, 114, 253, 0.08);
          margin-right: 8px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-evenly;
          animation: marqueeLeft 15s linear infinite;
          &:first-child {
            margin-left: 12px;
          }
          ._sm_img {
            display: none;
          }
          ._bg_img {
            width: 70px;
            height: 70px;
            // object-fit: cover;
          }
          ._tiview {
            display: flex;
            flex-direction: column;
            align-items: center;
            ._titl {
              font-family: Microsoft YaHei;
              font-size: 14px;
              letter-spacing: 0em;
              color: #3d3d3d;
              // margin-top: 9px;
              margin-bottom: 4px;
              font-weight: 700;
            }
            ._tagl {
              font-family: Microsoft YaHei;
              font-size: 10px;
              font-weight: normal;
              letter-spacing: 0em;
              color: #999999;
            }
          }
        }
      }
      .box_lis {
        // display: none;
      }
    }
  }
}
@keyframes marqueeLeft {
  from {
    transform: translateX(0);
    // transform: translateX(0%) translateY(0%); /* 自身节点*/
    // left: 100%; /* 继承父节点，让文字从最右侧开始显示*/
  }

  to {
    transform: translateX(-150%);
    // transform: translateX(-100px) translateY(0%);
    // left: 0;
  }
}
</style>
