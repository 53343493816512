<template>
  <div class="TophoneDialog _TophoneDialog">
    <div class="tophone_box _tophone_box">
      <div class="tel _tel">
        <a href="tel:400-085-0889">
          <img src="../../../assets/newimg/tel.png" alt="" />
        </a>
      </div>
      <div class="qr _qr" @click="toWxTap">
        <img src="../../../assets/newimg/qr_wz.png" alt="" />
      </div>
    </div>
    <div class="pc_phone _pc_phone">
      <div class="zxzx" @click="ddHrefLogin">
        <img src="../../../assets/newimg/zxzx_tou.png" alt="" />
        <div class="zxzx_text">
          <img src="../../../assets/newimg/zxzx_tex.png" alt="" />
        </div>
      </div>
      <div class="zxzx_cont">
        <div
          class="zxzx_phone"
          @mouseenter="mouseenterTap"
          @mouseleave="mouseleaveTap"
        >
          <img src="../../../assets/newimg/zxzx_phone.png" alt="" class="" />
        </div>
        <div class="zxzx_qr" @mouseenter="mouseenter" @mouseleave="mouseleave">
          <img src="../../../assets/newimg/zxzx_qr.png" alt="" class="" />
        </div>
      </div>
      <div class="zxzx_top">
        <div class="top_bj" @click="toTop">
          <img src="../../../assets/newimg/zxzx_top.png" alt="" />
        </div>
      </div>
      <div class="tel_box" style="display: none">
        <img src="../../../assets/newimg/tel_icon.png" alt="" />
      </div>
      <div class="qr_box" style="display: none">
        <img src="../../../assets/newimg/qr_icon.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TophoneDialog",
  data() {
    return {
      telBoxs: "",
    };
  },
  mounted() {},
  methods: {
    toTop() {
      document.documentElement.scrollTop = 0;
    },
    //移入
    mouseenter() {
      console.log("11111");
      document.getElementsByClassName("qr_box")[0].style.display = "block";
      document.getElementsByClassName("zxzx_qr")[0].style.background =
        "#005BD4";
    },
    mouseenterTap() {
      document.getElementsByClassName("tel_box")[0].style.display = "block";
      document.getElementsByClassName("zxzx_phone")[0].style.background =
        "#005BD4";
    },
    //移出
    mouseleave() {
      document.getElementsByClassName("qr_box")[0].style.display = "none";
      document.getElementsByClassName("zxzx_qr")[0].style.background =
        "#1377FE";
    },
    mouseleaveTap() {
      document.getElementsByClassName("tel_box")[0].style.display = "none";
      document.getElementsByClassName("zxzx_phone")[0].style.background =
        "#1377FE";
    },
    // handleShow() {
    //   const telBox = document.getElementsByClassName("tel_box");
    //   if (telBox[0].style.display == "none") {
    //     telBox[0].style.display = "block";
    //   } else {
    //     telBox[0].style.display = "none";
    //   }
    // },
    // 跳转链接钉钉操作
    ddHrefLogin() {
      console.log("888888888");
      // const { agentId, url } = this.ddConfig;
      // const cloneUrl = encodeURIComponent(url);
      // const cloneUrl = "https://cschat.antcloud.com.cn/index.htm";
      const agentId = "ilh_9ol2&scene=SCE01236050";
      const hrefUrl = `https://cschat.antcloud.com.cn/index.htm?tntInstId=${agentId}`;
      window.open(
        hrefUrl,
        "_blank",
        "page2.html",
        "newwindow2",
        "width=100,height=100, top=100, left=100,toolbar=no, menubar=no, scrollbars=no, resizable=no, location=no, status=no,"
      );
    },
    //微信咨询
    toWxTap() {
      // console.log("666666666");
      const hrefUrls = "https://work.weixin.qq.com/kfid/kfcdd204f82a434f980";
      window.open(hrefUrls);
    },
  },
};
</script>
<style lang="scss" scoped>
img {
  pointer-events: none;
}
// 大于等于800px
@media only screen and (min-width: 800px) {
  .TophoneDialog {
    width: 100px;
    height: 324px;
    position: fixed;
    right: 0;
    top: 50%;
    z-index: 9;
    .tophone_box {
      display: none;
    }
    .pc_phone {
      width: 100%;
      height: 324px;
      .zxzx {
        width: 100px;
        height: 107px;
        margin-bottom: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          width: 75px;
          height: 62px;
          // object-fit: cover;
        }
        .zxzx_text {
          width: 100px;
          height: 48px;
          border-radius: 100px;
          background: #1377fe;
          margin-top: -3px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          &:hover {
            background: #005bd4;
          }
          img {
            width: 64px;
            height: 21px;
          }
        }
      }
      .zxzx_cont {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 16px;
        .zxzx_phone {
          width: 64px;
          height: 64px;
          border-radius: 4px 4px 0px 0px;
          background: #1377fe;
          display: flex;
          justify-content: center;
          align-items: center;
          border-bottom: 0.5px solid #fff;
          cursor: pointer;
          // object-fit: cover;
          // border-radius: 4px 4px 0px 0px;
          img {
            width: 50%;
            height: 50%;
          }
        }
        .zxzx_qr {
          width: 64px;
          height: 64px;
          border-radius: 0px 0px 4px 4px;
          background: #1377fe;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          // object-fit: cover;
          // border-radius: 0px 0px 4px 4px;/
          img {
            width: 50%;
            height: 50%;
          }
        }
      }
      .zxzx_top {
        width: 100px;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
        .top_bj {
          width: 64px;
          height: 64px;
          border-radius: 4px;
          background-color: #fff;
          box-shadow: 0px 0px 16px 0px rgba(0, 88, 208, 0.16);
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
        img {
          width: 50%;
          height: 50%;
        }
      }
      .tel_box {
        width: 466.94px;
        height: 160px;
        position: fixed;
        right: 4%;
        top: 66%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .qr_box {
        width: 349.94px;
        height: 455px;
        position: fixed;
        right: 4%;
        top: 48%;
        display: none;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
// 小于800px
@media only screen and (max-width: 799px) {
  ._TophoneDialog {
    width: 311px;
    height: 50px;
    position: fixed;
    bottom: 28px;
    left: 32px;
    z-index: 9;
    ._tophone_box {
      width: 100%;
      height: 50px;
      border-radius: 50px;
      box-shadow: 0px 0px 16px 0px rgba(61, 61, 61, 0.16);
      background-color: #fff;
      display: flex;
      img {
        width: 101px;
        height: 25px;
        object-fit: cover;
      }
      ._tel {
        width: 50%;
        height: 50px;
        border-radius: 50px 0 0 50px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      ._qr {
        width: 50%;
        height: 50px;
        border-radius: 0 50px 50px 0;
        background: #33b54f;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    ._pc_phone {
      display: none;
    }
  }
}
</style>
